import React, { Fragment } from 'react';

import {
  faBars,
  faPaperPlane,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react';
import { Link } from 'react-scroll';

import { DarkModeToggleNoSsr } from './DarkModeToggle';
import config from '../config/index.json';

const Menu = () => {
  const { navigation, company, callToAction } = config;
  const { name: companyName, logo } = company;
  return (
    <>
      <svg
        className={`main-divider hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-background transform translate-x-1/2`}
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
        aria-hidden="true"
      >
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>
      <Popover>
        <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
          <nav
            className="relative flex items-center justify-between sm:h-10 lg:justify-start"
            aria-label="Global"
          >
            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="">
                  <span className="sr-only">{companyName}</span>
                  <img
                    alt="logo"
                    className="h-16 w-auto sm:h-16 logo"
                    src={logo}
                  />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <PopoverButton
                    className={`bg-background-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-50`}
                  >
                    <span className="sr-only">Open main menu</span>
                    <FontAwesomeIcon
                      icon={faBars}
                      className="h-6 w-6"
                      aria-hidden={true}
                    ></FontAwesomeIcon>
                  </PopoverButton>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
              {navigation.map((item) => (
                <Link
                  spy={true}
                  smooth={true}
                  duration={1000}
                  key={item.name}
                  to={item.href}
                  className="font-medium text-gray-500 hover:text-gray-900"
                >
                  {item.name}
                </Link>
              ))}
              <a
                href={callToAction.href}
                className={`font-medium text-primary-600 hover:text-primary-300`}
              >
                {callToAction.text}
                <FontAwesomeIcon icon={faPaperPlane} className={'mx-2'} />
              </a>
            </div>
            <DarkModeToggleNoSsr />
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <PopoverPanel className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            {({ close }) => (
              <div
                className={`rounded-lg shadow-md bg-background-50 ring-1 ring-black ring-opacity-5 overflow-hidden`}
              >
                <div className="px-5 pt-4 flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={logo} alt="" />
                  </div>
                  <div className="-mr-2">
                    <PopoverButton
                      className={`bg-background-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-50`}
                    >
                      <span className="sr-only">Close main menu</span>
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="h-6 w-6"
                        aria-hidden={true}
                      ></FontAwesomeIcon>
                    </PopoverButton>
                  </div>
                </div>
                <div className="px-2 pt-2 pb-3 space-y-1">
                  {navigation.map((item) => (
                    <Link
                      spy={true}
                      active="active"
                      smooth={true}
                      duration={1000}
                      key={item.name}
                      to={item.href}
                      onClick={close}
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
                <a
                  href={callToAction.href}
                  className={`block w-full px-5 py-3 text-center font-medium text-primary-600 bg-gray-50 hover:bg-gray-100`}
                >
                  {callToAction.text}
                </a>
              </div>
            )}
          </PopoverPanel>
        </Transition>
      </Popover>
    </>
  );
};

export default Menu;
